import React from "react"
import { graphql } from "gatsby"

import ReactMarkdown from "react-markdown"
import Moment from "react-moment"

import Layout from "../components/layout"

// export const query = graphql`
//   query PostQuery($slug: String!) {
//     strapiPost(slug: { eq: $slug }) {
//       strapiId
//       title
//       body
//       published_at
//     }
//   }
// `

const Post = ({ data }) => {
  const post = data ? data.strapiPost : {}
  return (
    <Layout>
      <ReactMarkdown source={post.content} />
      <p>
        <Moment format="MMM Do YYYY">{post.published_at}</Moment>
      </p>
    </Layout>
  )
}

export default Post
